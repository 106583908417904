.sliderDIv {
  display: flex !important;
  text-align: center;
}
.caseBtn {
  background-color: #041527;
  padding: 10px;
  color: #ffff;
}
@media (max-width: 991px) {
  .themeBlack {
    font-size: 16px;
  }
  .caseBtn {
    font-size: 13px;
  }
}
