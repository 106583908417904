.location-wrapper {
  background-color: #ffffff;
  /* padding: 40px 0; */
}

.i4ewOd-pzNkMb-haAclf {
  display: none !important;
}

.location-details {
  background-color: #111;
  color: white;
  border-radius: 8px;
  margin-bottom: 20px;
}

.location-details h4 {
  font-weight: bold;
  font-size: 1.5rem;
}

.location-item i {
  color: #ffc107; /* Yellow icon for locations */
}

.contact-info i {
  color: #ffc107; /* Yellow icon for contact info */
}

.map-container {
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  height: 350px;
}

@media (min-width: 768px) {
  .location-wrapper {
    padding: 20px;
  }

  .location-details {
    max-width: 400px;
  }

  .map-container {
    height: 450px; /* Increase map height for larger screens */
  }
}
