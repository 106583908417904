.contactUsContainer {
  padding: 0px 0 40px;
}
.contactUs_Banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 40px;
  /* background-color: #e6edf7; */
  padding: 20px 0;
  border-radius: 20px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .contactUs_Banner {
    row-gap: 0px;
  }
  .contactUsContainer {
    padding: 0px !important;
  }
}
