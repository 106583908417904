.profile-left-img {
  width: 100%;
  text-align: center;
}
.profile-left-img .profile-staric {
  padding: 46px 0px 8px 0px;
  font-size: 78px;
  border: 1px solid #041527 !important;
  margin: auto;
  width: 33%;
  border-radius: 50%;
}
.profile-section .slick-dots {
  position: absolute;
  bottom: -14px;
  display: block;
  width: 100%;
  left: 70px;
  padding: 0;
  margin: 0;
  text-align: left;
  text-align: center;
}
.profile-section .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
  border: 1px solid #041527 !important;
  border-radius: 50%;
}
.profile-section .para {
  margin: 15px 0px !important;
}

@media only screen and (max-width: 991px) {
  .profile-left-img .profile-staric {
    width: 100px !important;
  }
  .profile-section .slick-dots {
    bottom: -10px !important;
  }
}
@media (max-width: 768px) {
  .profile-left-img .profile-staric {
    padding: 16px 10px 0px 10px;
    font-size: 36px;
    border: 1px solid #041527 !important;
    margin: auto;
    width: 63px !important;
    border-radius: 50%;
  }
  .profile-section .profile-right-content {
    margin-left: 20px !important;
  }
  .profile-section .profile-right-content .themeBlack {
    font-size: 14px;
  }
  .profile-section .profile-right-content .para {
    margin: 0px !important;
    font-size: 10px;
  }
  .profile-section .slick-dots {
    left: -30px;
    bottom: -29px !important;
  }
}
