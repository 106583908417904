* {
  padding: 0;
  margin: 0;
}
body {
  /* background-color: #0e0c15; */
  font-size: 16px;
  line-height: 2;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #041527;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #041527;
}

img {
  max-width: 100%;
  height: auto;
}

.mainHeading {
  font-size: 73px;
  line-height: 80px;
  font-weight: 400;
  max-width: 1020px;
  margin: 0 auto;
  color: #041527;
  margin-bottom: 34px;
}
.para {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 30px;
  color: #d3d5d8;
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
}
.themeBlack {
  color: #041527;
}
.themeWhite {
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .para {
    font-size: 13px !important;
  }
  .mainHeading {
    font-size: 18px;
    line-height: 0px;
  }
}
@media (max-width: 768px) {
  .para {
    font-size: 14px !important;
    line-height: 20px;
  }
  .mainHeading {
    font-size: 24px;
    line-height: 30px;
  }
}
