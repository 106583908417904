.banner-latestProject {
  background-color: #000;
}

.latest-banner {
  /* background-image: url("../assets/Section.png");
  background-position: bottom center;
  background-size: cover; */
}

.display-4 {
  font-size: 35px;
  font-weight: bold;
}

.lead {
  max-width: 500px;
  font-size: 18px !important;
  line-height: 24px;
}
.banner-latestProject {
  position: relative;
}
.side-Img {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.side-Img img {
  height: 100%;
}
.img-fluid {
  margin: 0 auto;
}

@media only screen and (max-width: 991px) {
  .banner-latestProject {
    width: 100%;
  }
  .display-4 {
    font-size: 16px;
  }
  .lead {
    font-size: 13px;
  }
  .side-Img {
    display: none;
  }
  .banner-latestProject {
    background-image: url("../assets/Section.png");
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (max-width: 768px) {
  .display-4 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
}
