/* General styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.container {
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}

.description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.custom-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.custom-box {
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  color: #fff;
}
.element {
  background-image: url("../assets/flag3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.element2 {
  background-image: url("../assets/slideShow2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ranchers {
  background-color: #c0392b;
}

.hedexam {
  background-color: #000000; /* Hedexam background */
}

.ximi-vogue {
  background-color: #000000; /* Ximi Vogue background */
}

.pec {
  background-color: #2c3e50; /* PEC background */
}

.see-more {
  background-color: #ecf0f1; /* See More background */
  color: #333;
}

/* Responsiveness */
@media (max-width: 991px) {
  .custom-box h4 {
    font-size: 16px;
  }
  .custom-box p {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .custom-box {
    height: 150px;
  }

  h1 {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1rem;
  }
  .custom-box h4 {
    font-size: 14px;
  }
  .custom-box p {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .custom-box {
    height: 120px;
    padding: 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.9rem;
  }
}
