.our-product-section {
  margin-top: 50px;
}

.category-heading {
  color: #041527;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.tech-card {
  width: 100%;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.tech-card:hover {
  transform: translateY(-5px);
}

.card-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.card-img-top {
  max-width: 80px;
  max-height: auto;
}

.tech-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.tech-description {
  font-size: 14px;
  color: #555;
}

.our-product {
  padding: 20px 0 40px;
}
.card-img-top {
  max-width: 100%;
  height: auto;
}
.our-product .container .card-body {
  padding: 10px 0;
  border: none;
}
.our-product .container .card {
  border: none;
  background-color: transparent;
}
.our-product .container .card .card-body .card-title {
  font-size: 27px;
  line-height: 33px;
  font-weight: bold;
  font-style: normal;
  color: #454654;
}
.our-product .container .card .card-body .card-text {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #454654;
}
.link-readmore {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  font-style: normal;
  color: #eb3f57;
  border-bottom: 1px solid #eb3f57;
  padding-bottom: 5px;
  cursor: pointer;
}
.link-readmore:hover {
  border-bottom: none;
}
.our-product .container .card-grid {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
  flex-wrap: wrap;
}
.middle-card {
  margin-top: 100px;
}
@media (max-width: 992px) {
  .middle-card {
    margin-top: 0px;
  }
}
@media (max-width: 769px) {
  .our-product .container .card .card-body .card-title {
    font-size: 22px;
    line-height: 28px;
  }
  .our-product .container .card .card-body .card-text {
    font-size: 14px;
    line-height: 20px;
  }
  .link-readmore {
    font-size: 12px;
    line-height: 18px;
  }
}
@media screen and (min-width: 480px) {
  .our-product {
    padding: 20px 0 20px;
  }
}
