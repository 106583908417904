.workingapproach {
  padding: 71px 0 100px 0;
  margin-top: 0 !important;
  background-color: #f7f7fa;
}
.wa-card {
  padding: 10px 20px;
  background-color: #f7f7fa;
  border: 1px solid #e7daed;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.wa-card-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #1a202c;
  margin: 5px 0px;
}
.wa-card-dec {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #4a5568;
}
.img-div {
  padding: 9px 12px;
  background-color: #000000;
  border-radius: 10px;
}

@media only screen and (max-width: 991px) {
  .wa-card-heading {
    font-size: 16px;
  }
  .wa-card-dec {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .workingapproach {
    padding: 20px;
  }
}
