.ourTeam {
  padding: 60px 0;
}
.ourTeam .container .row {
  align-items: center;
}
.ourTeamHeading {
  border-bottom: 1px solid #eb3f57;
  /* width: 50%; */
  margin: 0 auto;
  padding-bottom: 10px;
  font-size: 45px;
  line-height: 51px;
}
.image-div {
  object-fit: contain;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 363px;
}
.team-desc {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.team-desc h4 {
  align-items: center;
  font-size: 27px;
  line-height: 33px;
  font-weight: normal;
  font-style: normal;
  color: #ffffff;
}
.team-desc p {
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  font-style: normal;
  color: #ffffff;
}
.shade {
  position: absolute;
  top: 0;
  background-color: #4546546e;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .team-desc h4 {
    font-size: 18px;
  }
}
