.aboutUs {
  padding: 10px 0 0 0;
  position: relative;
  overflow: hidden;
}

.aboutUscol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.aboutUs-heading {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
  font-style: normal;
  color: #041527;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.li-list {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  color: #041527;
  margin-bottom: 10px;
  list-style: circle;
}
.aboutUs-heading:after {
  content: "";
  position: absolute;
  width: 92px;
  height: 4px;
  background-color: #041527;
  bottom: -17px;
  left: 0;
}
.aboutUscol .P-heading {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  color: #041527;
  margin-bottom: 30px;
}
.aboutUscol .number {
  font-size: 40px;
  line-height: 46px;
  font-weight: bold;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  padding-bottom: 0;
  border: none;
}
.aboutUscol .p-with-border {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  color: #041527;
  padding-bottom: 30px;
  /* border-bottom: 1px solid #808080;
  margin-right: 30px; */
}
imgdiv .aboutUscol .p-without-border {
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  color: rgb(4, 21, 39);
  /* padding-bottom: 30px; */
  /* border-bottom: 1px solid #808080; */
  margin-right: 30px;
}
.aboutUsimg {
  height: auto;
  width: 100%;
}
.imgdiv {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
}
.imgdiv img {
  box-shadow: 64px 64px 160px -40px rgba(0, 0, 0, 0.24);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutUsRow {
  margin-bottom: 80px;
}
.imgdiv::after {
  content: "";
  position: absolute;
  padding: 20px px;
  width: 94%;
  height: 100%;
  bottom: -16px;
  right: -15px;
  border: 4px solid #c7ced9;
  z-index: -1;
}
.imgdiv2 {
  position: relative;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
}
.imgdiv2 img {
  box-shadow: 64px 64px 160px -40px rgba(0, 0, 0, 0.24);
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgdiv2::after {
  content: "";
  position: absolute;
  padding: 20px px;
  width: 94%;
  height: 100%;
  bottom: -16px;
  right: -18px;
  border: 4px solid #c7ced9;
  z-index: 0;
}
.col-lg-6 {
  z-index: 1;
  margin-left: 30px;
}

/* .imgdiv {
  position: absolute;
} */
@media (max-width: 1119px) {
  .aboutUs-heading {
    font-size: 30px;
    line-height: 36px;
  }

  .aboutUscol p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 992px) {
  .imgdiv {
    position: relative;
  }
  .aboutUsRow .col-lg-6 {
    margin-left: 5px;
  }
}
