.carousel-container {
  text-align: center;
  background-color: #041527; /* Dark background to match your example */
  position: relative;
  padding-bottom: 10px;
}

.carousel-container h3 {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
}

.slick-slide img {
  max-width: 100%;
  height: 100%;
}
.moon-img {
  position: relative;
  background-color: #041527;
  margin: 0 auto;
}
.moon-img::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  bottom: 0;
}
.sliderImgDiv {
  /* width: 140px !import
  ant; */
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  height: 51px;
}
