.banner-section {
  height: 100vh;
  position: relative;
  /* padding-top: 65px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #041527;
}
.Banner-row {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; /* This ensures text is centered within the column */
  padding-top: 80px;
}
.main-heading {
  font-size: 70px;
  letter-spacing: 2px;
  font-weight: bold;
  line-height: 77px;
  color: #e6e7e8;
  max-width: 929px;
}
.banner-img {
  max-width: 100%;
  height: auto;
}
.gradient {
  color: #f5d68e;

  /* background-image: linear-gradient(to right, #f7666f, #406aff); */
}

.col-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 30px;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 12rem;

  height: auto;
}
button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #0057ff;
  /* background-image: linear-gradient(to right, #f7666f, #406aff); */
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #e6e7e8;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}

@supports (display: grid) {
  #container {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }
}

@media only screen and (max-width: 991px) {
  .main-heading {
    font-size: 30px;
    line-height: 50px;
  }
}
@media (max-width: 768px) {
  .main-heading {
    font-size: 24px;
    line-height: 30px;
  }
  .button-text {
    font-size: 16px;
    line-height: 22px;
  }
  .Banner-row {
    padding-top: 20px;
  }
}
