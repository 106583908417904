header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 999;
}
.navbar-full {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 15px 0 5px !important;
}
.navbar-link {
  display: flex;
  align-items: center;
  column-gap: 35px;
  text-align: center;
  margin: 0;
}
.navbar-link-li {
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: bold;
  line-height: 22px;
  color: #041527;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.logo h2 {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #f7666f, #406aff);
}
.constact-us {
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
  padding: 10px 20px;
  background-color: #0057ff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
}
.navbar-full .mobile-menu {
  display: none;
}
/* .navbar-full .mobile-menu .menu-items {
  display: none;
} */
.menu-items {
  display: none; /* Hide menu items by default */
}

.menu-items.open {
  display: block; /* Show menu items when `open` class is added */
}
.constact-us-responsive {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 22px;
  color: #ffffff;
  padding: 10px 20px;
  background-color: #0057ff;
  border: none;
  border-radius: 20px;
  display: none;
}
@media only screen and (max-width: 991px) {
  /* NAVBAR */
  .constact-us-responsive {
    display: block;
  }
  .navbar-link {
    display: none;
  }
  .constact-us {
    display: none;
  }
  .navbar-full .mobile-menu {
    display: block;
    position: relative;
  }
  .ham-icon {
    font-size: 25px;
    padding: 5px;
  }
  .navbar-full .mobile-menu .menu-items {
    position: absolute;
    font-size: 16px;
    background-color: #faf8ed;
    right: 23px;
    padding: 21px;
    width: 200px;
    z-index: 10;
    background: #041527;
  }
  .navbar-full .mobile-menu .menu-items ul {
    list-style-type: none;
    padding: 0px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .navbar-full .mobile-menu .menu-items ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
  }
  .navbar-full .mobile-menu .menu-items ul li a:hover {
    text-decoration: none;
    opacity: 0.7;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .navbar-full {
    padding: 10px 20px !important;
  }
  .navbar-full .logo {
    width: 120px;
  }
}
