.navbar-link-li {
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: bold;
  line-height: 22px;
  color: #041527;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
@media (max-width: 991px) {
  .text-uppercase {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
  .list-unstyled {
    font-size: 13px;
  }
  .bg-light {
    font-size: 14px;
  }
}
