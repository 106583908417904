.Card-grid-section {
  padding: 30px 0;
}
.lastcard {
  box-shadow: 0px 0px 8px rgba(120, 120, 171, 0.7);
  padding: 0px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #454654;
  margin-bottom: 10px;
  border-radius: 10px;
  min-height: 400px;
}
.lastcard h2 {
  font-size: 27px;
  line-height: 33px;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
}
.lastcard p {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #ffffff;
}
.lastcard-middle {
  box-shadow: 0px 0px 8px rgba(120, 120, 171, 0.7);
  background-color: #ffffff;
  padding: 0px 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 10px;
  min-height: 400px;
}
.lastcard-middle h2 {
  font-size: 27px;
  line-height: 33px;
  font-weight: bold;
  font-style: normal;
  color: #454654;
}
.lastcard-middle p {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #454654;
}
